import {BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Aboutus from "./Pages/Aboutus/Aboutus";
import APPProducts from "./Pages/Products/APPProducts";
import Catalogue from "./Pages/Catalogue/Catalogue";
import Blog from "./Pages/Blog/Blog";
import Getaquote from "./Pages/Getaquote/Getaquote";
import Notfound from "./Pages/Notfound/Notfound";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Head from "./Components/Head";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Head />
      <Navbar/>
      <Routes>
        <Route path='/' element={<Navigate to='home'/>}/>
        <Route path='home' element={<Home/>}/>
        <Route path='aboutus' element={<Aboutus/>}/>
        <Route path='products' element={<APPProducts/>}/>
        <Route path='catalogue' element={<Catalogue/>}/>
        <Route path='blog' element={<Blog/>}/>
        <Route path='getaquote' element={<Getaquote/>} />
        <Route path='*' element={<Notfound/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
