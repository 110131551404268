import React from "react";
import "./Home.css";
import SliderHome from "./Slider"
import Categories from "./Categories/Categories"
import Sector from "./Sectors/Sector"
import SpecialAds from "./SpecialAd/SpecialAds"
import Clients from "./Clients/Clients"


const Home = () => {
    return (
        <div className="imgmain">
            <SliderHome />
            <Categories />
            <Sector />
            <SpecialAds />
        </div>
    );
};

export default Home;