const SpecialAddata = [
  {
    cover: "./images/top/sandwich papers.jpg",
    para: "Best Pick",
    desc: "Sandwich Papers Printed & White",
  },
  {
    cover: "./images/top/aluminiumfoil.jpg",
    para: "Brand New",
    desc: "Aluminium Foil 45CM & 30CM",
  },
  {
    cover: "./images/top/spl.jpg",
    para: "Best Pick",
    desc: "Microwave Basins",
  },
  {
    cover: "./images/top/maxiroll.jpg",
    para: "Top Sale",
    desc: "Maxirolls",
  },
  {
    cover: "./images/top/zoyu liquidwash.jpg",
    para: "Best Pick",
    desc: "Dishwash & Handwash",
  },
]

export default SpecialAddata;
