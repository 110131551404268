import React from "react";
import "./Aboutus.css";

const Aboutus = () => {
    return (
        <div>
            <img src="images/sultanlogomain.svg" alt=""/>
            <h1> About Us Page Loading... </h1>
        </div>
    );
};

export default Aboutus;