import React from "react";
import "./Footer.css";
import {Link} from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";


const Footer = () => {
    return (
        <footer>
            <div className="container footer__container">
                <article>
                    <Link to="/home" className="logo2">
                        <img src="images/sultanlogomainwhite.svg" alt="" />
                    </Link>
                    <div className="footer__socials">
                        <a href="https://www.facebook.com/sultantradingqa" target="_blank" rel="noreferrer noopener"><FaFacebookF/></a>
                        <a href="https://www.instagram.com/sultantradingqa" target="_blank" rel="noreferrer noopener"><AiFillInstagram/></a>
                        <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer noopener"><FaLinkedin/></a>
                        <a href="https://www.twitter.com/" target="_blank" rel="noreferrer noopener"><AiOutlineTwitter/></a>
                    </div>
                </article>
                <article>
                    <h4>Links</h4>
                    <Link to="/home">Home</Link>
                    <Link to="/aboutus">About Us</Link>
                    <Link to="/products">Products</Link>
                    <Link to="/catalogue">Catalogue</Link>
                    <Link to="/blog">Blog</Link>
                    <Link to="/getaquote">Get a Quote</Link>
                </article>
                <article>
                    <h4>Categories</h4>
                    <Link to="/products">Paper Products</Link>
                    <Link to="/products">Plastic Products</Link>
                    <Link to="/products">Aluminium Products</Link>
                    <Link to="/products">Hygine and Protection</Link>
                    <Link to="/products">Soft N Cool Products</Link>
                </article>
                <article>
                    <h4>Address</h4>
                    <a>Birkath Al Awamer, Al Wukair, Qatar</a>
                    <a>Phone: +974 3000 2747, +974 400 26 500</a>
                    <a>Mail id: sales@sultanqatar.com</a>
                </article>
            </div>
            <div className="footer__copyright">
                <small>Copyright 2020 &copy; Sultan International Trading and Services - All Rights Reserved</small>
            </div>
        </footer>
    );
};

export default Footer;