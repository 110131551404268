import { AiFillStar } from "react-icons/ai";

const data = [
  {
    img: "./Productimg/Paperproducts/2.5&4.png",
    title: "2.5oz Paper Tea Cup",
    packing: "50 Pieces x 40 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/2.5&4.png",
    title: "4oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Yellowblack.png",
    title: "6oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/redblack.png",
    title: "7oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Yellowblack.png",
    title: "7oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/8ozblackred.png",
    title: "8oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/8ozblackred.png",
    title: "12oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/8ozblackred.png",
    title: "16oz Paper Tea Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/4ozdw.png",
    title: "4oz Double Wall Paper Tea Cup",
    packing: "50 Pieces x 10 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/81216.png",
    title: "8oz Double Wall Paper Tea Cup",
    packing: "50 Pieces x 10 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/81216.png",
    title: "12oz Double Wall Paper Tea Cup",
    packing: "50 Pieces x 10 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/81216.png",
    title: "16oz Double Wall Paper Tea Cup",
    packing: "50 Pieces x 10 Packets",
    company: "SULTAN",
    color: "red",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/8rpc.png",
    title: "8oz Ripple Cup",
    packing: "25 Pieces x 40 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/1216rpc.png",
    title: "12oz Ripple Cup",
    packing: "25 Pieces x 40 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/1216rpc.png",
    title: "16oz Ripple Cup",
    packing: "25 Pieces x 40 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Lid.png",
    title: "7oz Cup Lid",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Lid.png",
    title: "8oz Cup Lid",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Lid.png",
    title: "12/16oz Cup Lid",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/JC1.png",
    title: "12oz Juice Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/JC2.png",
    title: "16oz Juice Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/SandwichpaperWhite.png",
    title: "MG White Sandwich Paper",
    packing: "10 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Sandwichpaperprinted.png",
    title: "Printed Sandwich Paper",
    packing: "5 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/Burgerfoil.png",
    title: "Burger Foil",
    packing: "500 Pieces x 4 Packets",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/bakingsheet.png",
    title: "Baking Sheets",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/burgerbox.png",
    title: "Burger Box",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/snackbox.png",
    title: "Snack Box",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/clubbox.png",
    title: "Club Box",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/lunchboxlarge.png",
    title: "Lunch Box Large",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/2canteacupholder.png",
    title: "2 Can Tea Cup Holder",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/4canteacupholder.png",
    title: "4 Can Tea Cup Holder",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/2canjuicecupholder.png",
    title: "2 Can Juice Cup Holder",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/4canjuicecupholder.png",
    title: "4 Can Juice Cup Holder",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/smallpizza.png",
    title: "Small Pizza Box",
    packing: "100 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/mediumpizza.png",
    title: "Medium Pizza Box",
    packing: "100 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/largepizza.png",
    title: "Large Pizza Box",
    packing: "100 Pieces",
    company: "SULTAN",
    color: "black",
    category: "paper",
  }, 
  {
    img: "./Productimg/Paperproducts/brownpaperbag.png",
    title: "Brown Paper bag",
    packing: "All Sizes Available",
    company: "SULTAN",
    color: "brown",
    category: "paper",
  },
  {
    img: "./Productimg/Paperproducts/whitepaperbag.png",
    title: "White Paper Bag",
    packing: "All Sizes Available",
    company: "SULTAN",
    color: "white",
    category: "paper",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-10 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-12 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-16 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-24 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-32 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-38 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/blackroundbasin.png",
    title: "RO-48 Black Round Basin",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/microwavebowl.png",
    title: "2oz Microwave Bowl & Lid",
    packing: "1000 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwavebowl.png",
    title: "4oz Microwave Bowl & Lid",
    packing: "1000 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/microwavebowl.png",
    title: "225 ML Microwave Bowl & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/microwavebowl.png",
    title: "250 ML Microwave Bowl & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },  
  {
    img: "./Productimg/Plasticproducts/microwavebowl.png",
    title: "350 ML Microwave Bowl & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  }, 
  {
    img: "./Productimg/Plasticproducts/microwavebowl.png",
    title: "450 ML Microwave Bowl & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },  
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-8 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-12 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-16 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-24 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-28 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-32 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-38 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-48 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/microwaveblackbase.png",
    title: "RE-58 Microwave Black Base",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/1compartment.png",
    title: "One Compartment",
    packing: "250 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/2compartment.png",
    title: "Two Compartment",
    packing: "250 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/3compartment.png",
    title: "Three Compartment",
    packing: "250 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/4compartment.png",
    title: "Four Compartment",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/5compartment.png",
    title: "Five Compartment",
    packing: "200 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/6compartment.png",
    title: "Six Compartment",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/500mlmicrowavecontainer.png",
    title: "500 ML Microwave Container & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/650mlmicrowavecontainer.png",
    title: "650 ML Microwave Container & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/750mlmicrowavecontainer.png",
    title: "750 ML Microwave Container & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/1000mlmicrowavecontainer.png",
    title: "1000 ML Microwave Container & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/1500mlmicrowavecontainer.png",
    title: "1500 ML Microwave Container & Lid",
    packing: "500 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/1ozwhiteppcup.png",
    title: "1oz White PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/2ozwhiteppcup.png",
    title: "2oz White PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/3ozwhiteppcup.png",
    title: "3oz White PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/4ozwhiteppcup.png",
    title: "4oz White PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/200mlwhiteppcup.png",
    title: "200 ML White PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/250mlwhiteppcup.png",
    title: "250 ML White PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/350mlwhiteppcup.png",
    title: "350 White PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/500mlwhiteppcup.png",
    title: "500 ML White PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "1oz Clear PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "2oz Clear PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "3oz Clear PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "4oz Clear PP Cup",
    packing: "100 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "200 ML Clear PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "250 ML Clear PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "350 Clear PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearppcup.png",
    title: "500 ML Clear PP Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupdome.png",
    title: "12 oz PET Juice Cup - Dome",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupflat.png",
    title: "12 oz PET Juice Cup - Flat",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupdome.png",
    title: "12 oz PP Juice Cup - Dome",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupflat.png",
    title: "12 oz PP Juice Cup - Flat",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupdome.png",
    title: "16 oz PET Juice Cup - Dome",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupflat.png",
    title: "16 oz PET Juice Cup - Flat",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupdome.png",
    title: "16 oz PP Juice Cup - Dome",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupflat.png",
    title: "16 oz PP Juice Cup - Flat",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupdome.png",
    title: "20 oz PET Juice Cup - Dome",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupflat.png",
    title: "20 oz PET Juice Cup - Flat",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupdome.png",
    title: "20 oz PP Juice Cup - Dome",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/juicecupflat.png",
    title: "20 oz PP Juice Cup - Flat",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearplasticcup.png",
    title: "6.5 oz Clear Plastic Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/whiteplasticcup.png",
    title: "6.5 oz White Plastic Cup",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/saladcover.png",
    title: "Salad Cover",
    packing: "20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/sufratablesheet.png",
    title: "Sufra Table Sheet Small",
    packing: "(15 Pieces x 2 Packets) x 20 Packets",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/sufratablesheet.png",
    title: "Sufra Table Sheet Large",
    packing: "100 Sheets x 12 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clingfilm30.png",
    title: "Cling Film 30 CM",
    packing: "6 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clingfilm45.png",
    title: "Cling Film 45 CM",
    packing: "6 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clingfilm45.png",
    title: "Cling Film 45 CM Jumbo",
    packing: "1 Pieces",
    company: "SULTAN",
    color: "clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbagsmall.png",
    title: "Shopping Bag Small",
    packing: "1 Carton",
    company: "SULTAN",
    color: "blue",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbagmedium.png",
    title: "Shopping Bag Medium",
    packing: "1 Carton",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbaglarge.png",
    title: "Shopping Bag Large",
    packing: "1 Carton",
    company: "SULTAN",
    color: "blue",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbagld.png",
    title: "LD Shopping Bag Small",
    packing: "2 Kg x 10 Bundle",
    company: "SULTAN",
    color: "yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbagld.png",
    title: "LD Shopping Bag Medium",
    packing: "2 Kg x 10 Bundle",
    company: "SULTAN",
    color: "yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbagld.png",
    title: "LD Shopping Bag Large",
    packing: "2 Kg x 10 Bundle",
    company: "SULTAN",
    color: "yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbaggd.png",
    title: "Gold Printed Shopping Bag Small",
    packing: "10 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbaggd.png",
    title: "Gold Printed Shopping Bag Medium",
    packing: "10 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/shoppingbaggd.png",
    title: "Gold Printed Shopping Bag Large",
    packing: "10 Packets",
    company: "SULTAN",
    color: "yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagblack.png",
    title: "65x80 Garbage Bag Black",
    packing: "10 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagblack.png",
    title: "85x110 Garbage Bag Black",
    packing: "10 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagblack.png",
    title: "90x110 Garbage Bag Black",
    packing: "6 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagblack.png",
    title: "95x110 Garbage Bag Black",
    packing: "6 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagblack.png",
    title: "124x140 Garbage Bag Black",
    packing: "20 Pieces x 4 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagyellow.png",
    title: "65x80 Garbage Bag Yellow",
    packing: "10 Pieces x 20 Packets",
    company: "SULTAN",
    color: "Yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagyellow.png",
    title: "85x110 Garbage Bag Yellow",
    packing: "10 Pieces x 20 Packets",
    company: "SULTAN",
    color: "Yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagyellow.png",
    title: "90x110 Garbage Bag Yellow",
    packing: "6 Pieces x 20 Packets",
    company: "SULTAN",
    color: "Yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagyellow.png",
    title: "95x110 Garbage Bag Yellow",
    packing: "6 Pieces x 20 Packets",
    company: "SULTAN",
    color: "Yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/garbagebagyellow.png",
    title: "124x140 Garbage Bag Yellow",
    packing: "20 Pieces x 4 Packets",
    company: "SULTAN",
    color: "Yellow",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/trashbag.png",
    title: "Trash Bag - 5 Gallon",
    packing: "2000 Pieces/Bundle",
    company: "SULTAN",
    color: "Clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/trashbag.png",
    title: "Trash Bag - 8 Gallon",
    packing: "2000 Pieces/Bundle",
    company: "SULTAN",
    color: "Clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/trashbag.png",
    title: "Trash Bag - 10 Gallon",
    packing: "2000 Pieces/Bundle",
    company: "SULTAN",
    color: "Clear",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackspoon.png",
    title: "HD Black Plastic Spoon",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackspoon.png",
    title: "MD Black Plastic Spoon",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/whitespoon.png",
    title: "White Plastic Spoon",
    packing: "50 Pieces x 40 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearspoon.png",
    title: "Clear Plastic Spoon",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackfork.png",
    title: "HD Black Plastic Fork",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackfork.png",
    title: "MD Black Plastic Fork",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/whitefork.png",
    title: "White Plastic Fork",
    packing: "50 Pieces x 40 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearfork.png",
    title: "Clear Plastic Fork",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackknife.png",
    title: "HD Black Plastic Knife",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackknife.png",
    title: "MD Black Plastic Knife",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/whiteknife.png",
    title: "White Plastic Knife",
    packing: "50 Pieces x 40 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/clearknife.png",
    title: "Clear Plastic Knife",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/blackcutlery.png",
    title: "Black Cutlery Set",
    packing: "500 Sets",
    company: "SULTAN",
    color: "black",
    category: "plastic",
  },
  {
    img: "./Productimg/Plasticproducts/whitecutlery.png",
    title: "White Cutlery Set",
    packing: "500 Sets",
    company: "SULTAN",
    color: "white",
    category: "plastic",
  },
  {
    img: "./Productimg/Aluminiumproducts/aluminiumfoil30.png",
    title: "Aluminium Foil 30 CM",
    packing: "6 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/aluminiumfoil45.png",
    title: "Aluminium Foil 45 CM",
    packing: "6 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/aluminiumplatter.png",
    title: "6550 Aluminium Platter",
    packing: "150 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/aluminiumplatter.png",
    title: "6586 Aluminium Platter",
    packing: "100 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/aluminiumplatter.png",
    title: "65180 Aluminium Platter",
    packing: "50 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/aluminiumplatter.png",
    title: "65220 Aluminium Platter",
    packing: "50 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/8325.png",
    title: "8325 Aluminium Container",
    packing: "1000 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/8342.png",
    title: "8342 Aluminium Container",
    packing: "1000 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/8368.png",
    title: "8368 Aluminium Container",
    packing: "900 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/8389.png",
    title: "8389 Aluminium Container",
    packing: "1000 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/1120.png",
    title: "1120 Aluminium Container",
    packing: "400 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/83166.png",
    title: "83166 Aluminium Container",
    packing: "800 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/83185.png",
    title: "83185 Aluminium Container",
    packing: "400 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/83190.png",
    title: "83190 Aluminium Container",
    packing: "250 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Aluminiumproducts/73365.png",
    title: "73365 Aluminium Container",
    packing: "100 Pieces",
    company: "SULTAN",
    color: "silver",
    category: "aluminium",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/dishwash.png",
    title: "Dish Wash Liquid",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "yellow",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/handwash.png",
    title: "Hand Wash Liquid",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "pink",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/allpurpose.png",
    title: "All Purpose Cleaner",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/bleach.png",
    title: "Bleach Liquid",
    packing: "4 Pieces - 4 Litre",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/floorcleaner.png",
    title: "Floor Cleaner Liquid",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "lavender",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/antiseptic.png",
    title: "Antiseptic Disinfectant",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "brown",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/glasscleaner.png",
    title: "Glass Cleaner",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/ovencleaner.png",
    title: "Oven Cleaner",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/sanitizer.png",
    title: "Hand Sanitizer",
    packing: "80 ML",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/sanitizer500ml.png",
    title: "Sanitizer",
    packing: "500 ML",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/sanitizerliquid.png",
    title: "Sanitizer With Flavour",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/sanitizerliquid.png",
    title: "Sanitizer Without Flavour",
    packing: "4 Pieces - 5 Litre",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/airfreshner.png",
    title: "Air Freshner",
    packing: "ALL Sizes Available",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/blackmask.png",
    title: "Black Face Mask",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "black",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/bluemask.png",
    title: "Blue Face Mask",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "blue",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/whitemask.png",
    title: "white Face Mask",
    packing: "50 Pieces x 20 Packets",
    company: "SULTAN",
    color: "white",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/blackhairnet.png",
    title: "Black Hairnet",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "black",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/bluehairnet.png",
    title: "Blue Hairnet",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "blue",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/whitehairnet.png",
    title: "White Hairnet",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "white",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/vinylgloves.png",
    title: "Small Clear Vinyl Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/vinylgloves.png",
    title: "Medium Clear Vinyl Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/vinylgloves.png",
    title: "Large Clear Vinyl Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/vinylgloves.png",
    title: "X-Large Clear Vinyl Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/latexgloves.png",
    title: "Small Latex Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "white",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/latexgloves.png",
    title: "Medium Latex Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "white",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/latexgloves.png",
    title: "Large Latex Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "white",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/latexgloves.png",
    title: "X-Large Latex Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "white",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/hdpegloves.png",
    title: "Small HDPE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/hdpegloves.png",
    title: "Medium HDPE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/hdpegloves.png",
    title: "Large HDPE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/hdpegloves.png",
    title: "X-Large HDPE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: " clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/pegloves.png",
    title: "Small PE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/pegloves.png",
    title: "Medium PE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/pegloves.png",
    title: "Large PE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/pegloves.png",
    title: "X-Large PE Gloves",
    packing: "100 Pieces x 10 Packets",
    company: "SULTAN",
    color: "clear",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/safetyshoes.png",
    title: "Safety Shoes",
    packing: "1 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/shoecover.png",
    title: "Shoe Cover",
    packing: "100 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/brushwithstick.png",
    title: "Brush with Stick",
    packing: "1 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/wiper.png",
    title: "Wiper",
    packing: "1 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/mopwithhandle.png",
    title: "Mop with Handle",
    packing: "1 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Hygiene&protectionproducts/floorcleaningset.png",
    title: "Floor Cleaning Set",
    packing: "1 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "hygieneandprotection",
  },
  {
    img: "./Productimg/Soft&Cool/tissue.png",
    title: "Mafaz Facial Tissue",
    packing: "5 Pieces - 200 Pulls x 2 Ply",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "500 GM Maxiroll",
    packing: "1 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "800 GM Maxiroll",
    packing: "1 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "900 GM Maxiroll",
    packing: "1 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "1000 GM Maxiroll",
    packing: "1 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "500 GM Maxiroll",
    packing: "2 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "800 GM Maxiroll",
    packing: "2 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "900 GM Maxiroll",
    packing: "2 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "1000 GM Maxiroll",
    packing: "2 Ply - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "500 GM Maxiroll",
    packing: "Auto Cut - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "800 GM Maxiroll",
    packing: "Auto Cut - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "900 GM Maxiroll",
    packing: "Auto Cut - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/maxiroll.png",
    title: "1000 GM Maxiroll",
    packing: "Auto Cut - 6 Pieces",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/bedroll.png",
    title: "50 x 40 Bed Roll",
    packing: "",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Soft&Cool/bedroll.png",
    title: "60 x 40 Bed Roll",
    packing: "",
    company: "SULTAN",
    color: "white",
    category: "softandcool",
  },
  {
    img: "./Productimg/Stationeryproducts/a4paper.png",
    title: "Multipurpose A4 Paper",
    packing: "500 Sheets x 5 Packets - 80 GSM",
    company: "SULTAN",
    color: "white",
    category: "stationery",
  },
  {
    img: "./Productimg/Stationeryproducts/thermalpaper.png",
    title: "Thermal Paper Roll",
    packing: "50 Pieces - (80x80) MM",
    company: "SULTAN",
    color: "white",
    category: "stationery",
  },
  {
    img: "./Productimg/Stationeryproducts/cashdrawer.png",
    title: "Cash Drawer",
    packing: "1 Piece",
    company: "SULTAN",
    color: "black",
    category: "stationery",
  },
  {
    img: "./Productimg/Stationeryproducts/cashcountingmachine.png",
    title: "Cash Counting Machine",
    packing: "1 Piece",
    company: "SULTAN",
    color: "mixed",
    category: "stationery",
  },
];

export default data;
