const Cdata = [
  {
    cover: "./images/Categories/paper products.png",
    name: "Paper Products",
  },
  {
    cover: "./images/Categories/plastic products.png",
    name: "Plastic Products",
  },
  {
    cover: "./images/Categories/aluminium products.png",
    name: "Aluminium Products",
  },
  {
    cover: "./images/Categories/hygiene and Protection.png",
    name: "Hygiene and Protection",
  },
  {
    cover: "./images/Categories/soft and cool.png",
    name: "Soft and Cool Products",
  },
]

export default Cdata
