const Sdata = [
  {
    id: 1,
    title: "Aluminium Foil",
    desc: "300 MM & 450 MM",
    cover: "./images/SlideCard/Aluminium Foil.png",
  },
  {
    id: 2,
    title: "Maxi Roll",
    desc: "800 GSM, 900 GSM, 1000 GSM",
    cover: "./images/SlideCard/maxiroll.png",
  },
  {
    id: 3,
    title: "Black Round Basin",
    desc: "RO-12, RO-16, RO-24, RO-32",
    cover: "./images/SlideCard/Black Round Basin.png",
  },
  {
    id: 4,
    title: "Microwave Black Base",
    desc: "RE-12, RE-16, RE-24, RE-28, RE-32",
    cover: "./images/SlideCard/Microwave Black Base.png",
  },
  {
    id: 5,
    title: "Microwave Container with Lid",
    desc: "500 ML, 650 ML, 750 ML, 1000 ML, 1500 ML",
    cover: "./images/SlideCard/Microwave container with lid.png",
  },
  {
    id: 6,
    title: "Microwave Bowl with Lid",
    desc: "225 ML, 250 ML, 350 ML, 450 ML",
    cover: "./images/SlideCard/Microwave bowl with lid.png",
  },
]
export default Sdata
